import {
  Button,
  Card,
  ErrorMessage,
  Flex,
  InputText,
  StyleSheet,
  Toast,
} from '@shopuptech/expo-shopup-ui';
import { types } from '@shopuptech/ui-core';
import classNames from 'classnames/bind';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LOGIN_PATH } from '../constants';
import { isMobile } from '../Utils/helpers';
import styles from './login.module.css';
import { resetPwdInitAction } from './LoginStore/loginActions';

const cx = classNames.bind(styles);

interface ParamTypes {
  token?: string;
}

type FormInitialType = {
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormInitialType = {
  password: '',
  passwordConfirmation: '',
};

const uikitStyle = StyleSheet.create({
  inputFields: {
    minWidth: 300,
    marginTop: 14,
  },
  errorMessage: {
    marginBottom: 14,
  },
  forgotPasswordButton: {
    marginTop: 10,
  },
});

const handleValidate = (values: FormInitialType) => {
  const error: Partial<FormInitialType> = {};
  if (!values.password) {
    error.password = 'This field is required';
  }

  if (!values.passwordConfirmation) {
    error.passwordConfirmation = 'This field is required';
  }

  if (
    values.password &&
    !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d ]{10,}$/.test(values.password)
  ) {
    error.password =
      'Password must contain at least one uppercase letter, one lowercase letter and one number. No special characters are allowed!';
  }

  if (values.password && values.password.length < 10) {
    error.password = 'Password should have a minimum of 10 characters';
  }

  if (
    values.password &&
    values.passwordConfirmation &&
    values.password !== values.passwordConfirmation
  ) {
    error.passwordConfirmation = 'Passwords should be equal';
  }

  return error;
};

const ForgotPassword = () => {
  const { token } = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch: types.Dispatch<any> = useDispatch();
  const handleFormSubmit = (values: FormInitialType) => {
    if (token) {
      dispatch(resetPwdInitAction({ ...values, token }))
        .then(() => {
          Toast('Password has been successfully reset!!!');
          history.replace(LOGIN_PATH);
        })
        .catch((error) => handleErrorToast(error));
    } else {
      Toast('Url is invalid');
    }
  };

  const handleErrorToast = (error: any) => {
    if (error && error.details && error.details[0].message) {
      Toast(error.details[0].message);
    }
  };

  return (
    <div>
      <Flex center middle>
        <div
          className={
            isMobile()
              ? cx('forgotPwdContainerMobile')
              : cx('forgotPwdContainer')
          }>
          <Card testID="forgot-password-card" normal align={'stretch'} disabled>
            <div className={cx('fpinnerContainer')}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validate={handleValidate}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                  return (
                    <>
                      <Flex overrideStyle={uikitStyle.inputFields}>
                        <InputText
                          testID="new-password-input"
                          overrideStyle={uikitStyle.inputFields}
                          secureTextEntry
                          label={'Password'}
                          value={values.password}
                          onChange={handleChange('password')}
                          contain
                        />
                        <Flex overrideStyle={uikitStyle.errorMessage}>
                          <ErrorMessage
                            name={'password'}
                            errors={errors}
                            touched={touched}
                          />
                        </Flex>
                      </Flex>
                      <Flex overrideStyle={uikitStyle.inputFields}>
                        <InputText
                          testID="confirm-new-password-input"
                          overrideStyle={uikitStyle.inputFields}
                          secureTextEntry
                          label={'Confirm Password'}
                          value={values.passwordConfirmation}
                          onChange={handleChange('passwordConfirmation')}
                          contain
                        />
                        <Flex overrideStyle={uikitStyle.errorMessage}>
                          <ErrorMessage
                            name={'passwordConfirmation'}
                            errors={errors}
                            touched={touched}
                          />
                        </Flex>
                      </Flex>
                      <Button
                        overrideStyle={uikitStyle.forgotPasswordButton}
                        testID="reset-password-button"
                        onClick={handleSubmit}>
                        Reset Password
                      </Button>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Card>
        </div>
      </Flex>
    </div>
  );
};

export default ForgotPassword;
