import { permissionType } from './mainAction.types';
import {
  CHECK_LOGIN,
  CHECK_PERMISSIONS_INIT,
  CLEAR_PERMISSIONS_LIST,
  CLEAR_USER_INFO,
  LOGOUT_INIT,
  NAVBAR_COLLAPSE,
  UPDATE_PERMISSIONS,
} from './modules/mainhome/actions';

const moduleName = 'main';

export const checkLoginAction = () => ({
  type: CHECK_LOGIN,
  meta: {
    moduleName: moduleName,
  },
});

export const updatePermissionAction = (payload: permissionType) => ({
  type: UPDATE_PERMISSIONS,
  payload,
  meta: {
    moduleName: moduleName,
  },
});

export const checkPermissionsAction = () => ({
  type: CHECK_PERMISSIONS_INIT,
  meta: {
    moduleName: moduleName,
  },
});

export const logoutAction = () => ({
  type: LOGOUT_INIT,
  meta: {
    moduleName: moduleName,
  },
});

export const clearUserInfo = () => ({
  type: CLEAR_USER_INFO,
  meta: {
    moduleName: moduleName,
  },
});

export const clearPermissionsAction = () => ({
  type: CLEAR_PERMISSIONS_LIST,
});

export const openNavbar = () => ({
  type: NAVBAR_COLLAPSE,
  payload: false,
  meta: {
    moduleName: moduleName,
  },
});

export const closeNavbar = () => ({
  type: NAVBAR_COLLAPSE,
  payload: true,
  meta: {
    moduleName: moduleName,
  },
});

export const toggleNavbar = (payload: boolean) => ({
  type: NAVBAR_COLLAPSE,
  payload: payload,
  meta: {
    moduleName: moduleName,
  },
});
