import { combineReducers } from 'redux';
import {
  CheckLoginAllAction,
  CheckLoginState,
  CheckPermissionsAllAction,
  CollapseNavbarActionType,
  LogoutAllAction,
  LogoutState,
  NavbarState,
  PermissionScopeState,
} from './mainAction.types';
import {
  CHECK_LOGIN,
  CHECK_LOGIN_ERROR,
  CHECK_LOGIN_SUCCESS,
  CHECK_PERMISSIONS_ERROR,
  CHECK_PERMISSIONS_INIT,
  CHECK_PERMISSIONS_SUCCESS,
  CLEAR_PERMISSIONS_LIST,
  CLEAR_USER_INFO,
  LOGOUT_ERROR,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  NAVBAR_COLLAPSE,
  UPDATE_PERMISSIONS,
} from './modules/mainhome/actions';

const initialUserInfo: CheckLoginState = {
  isLoading: false,
  error: '',
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  emailVerified: false,
  phone: '',
  phoneVerified: false,
  created: '',
  updated: '',
  roles: [],
  scopes: [],
  name: '',
  isLoggedin: false,
};

const checkLogin = (
  state: CheckLoginState = initialUserInfo,
  action: CheckLoginAllAction,
) => {
  switch (action.type) {
    case CHECK_LOGIN:
      return {
        ...state,
        isLoading: true,
        isLoggedin: false,
        error: '',
      };
    case CHECK_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedin: true,
        error: '',
        ...action.payload,
      };
    case CHECK_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        ...initialUserInfo,
      };
    default:
      return state;
  }
};

const scopes = (
  state: PermissionScopeState = {
    isLoading: false,
    error: '',
    permissionList: [],
    permissions: {},
  },
  action: CheckPermissionsAllAction,
) => {
  switch (action.type) {
    case CHECK_PERMISSIONS_INIT:
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    case CHECK_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        permissionList: action.payload,
      };
    case CHECK_PERMISSIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_PERMISSIONS_LIST:
      return {
        ...state,
        permissionList: [],
      };
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

const logout = (
  state: LogoutState = {
    isLoading: false,
    error: '',
    success: '',
  },
  action: LogoutAllAction,
) => {
  switch (action.type) {
    case LOGOUT_INIT:
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const navbar = (
  state: NavbarState = {
    isCollapsed: false,
  },
  action: CollapseNavbarActionType,
) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case NAVBAR_COLLAPSE:
      return {
        ...state,
        isCollapsed: action.payload,
      };
    default:
      return state;
  }
};

const mainReducer = combineReducers({
  checkLogin,
  scopes,
  logout,
  navbar,
});

export default mainReducer;
