/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */
import type { Dispatch, MiddlewareAPI } from 'redux';
import { combineReducers, compose } from 'redux';
import panelMiddleWare from './mainMiddleWares';
import panelReducer from './mainReducers';
import { configureStore } from '@reduxjs/toolkit';
import { middlewares as middlewaresmainhome } from './modules/mainhome/middlewares';
import { reducers as reducersmainhome } from './modules/mainhome/reducers';

const reducersMap: { [key: string]: any } = {};

type ActionMetaType = {
  moduleName?: string;
};
export type ActionType = {
  type: any;
  meta: ActionMetaType;
  payload?: any;
};

const getCurrentStore = (moduleName: string) => {
  return {
    getState: () => store.getState()[moduleName],
    dispatch: (act: ActionType) => {
      if (!act.meta) act.meta = {};
      act.meta.moduleName = act.meta.moduleName
        ? act.meta.moduleName
        : moduleName;
      store.dispatch(act);
    },
  };
};

const rootMiddleware =
  (store: MiddlewareAPI<any, any>) =>
  (next: Dispatch) =>
  (action: ActionType) => {
    const { meta: { moduleName } = {} } = action;
    if (!moduleName) return next(action);

    let combinedWares: Function[] = [];

    if (moduleName === 'mainhome') {
      combinedWares = [...middlewaresmainhome];
    }

    if (moduleName === 'main') {
      combinedWares = [...panelMiddleWare];
    }

    const enachancedWares = combinedWares.map((ware) =>
      ware(getCurrentStore(moduleName)),
    );

    const wares: Function = compose(...enachancedWares);

    return wares(next)(action);
  };

reducersMap['main'] = (state: any = {}, action: ActionType) => {
  const { meta: { moduleName = '' } = {} } = action;
  if (moduleName !== 'main') return state;
  else return panelReducer(state, action);
};

reducersMap['mainhome'] = (state: any = {}, action: ActionType) => {
  const { meta: { moduleName = '' } = {} } = action;
  if (moduleName !== 'mainhome') return state;
  else return reducersmainhome(state, action);
};

export const mainReducers = combineReducers(reducersMap);

export type RootState = ReturnType<typeof mainReducers>;

export type AppStore = typeof store;
export type DispatchType = typeof store.dispatch;

const store = configureStore({
  reducer: mainReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootMiddleware),
});

export default store;
