import { Text, ToastBar } from '@shopuptech/expo-shopup-ui';
import { helpers, types, validators } from '@shopuptech/ui-core';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { logo, panelName, showTopbar } from './config';
import useRequireLogin from './hooks/requireLoginHook';
import {
  CheckLoginState,
  ItemsEntity,
  PermissionScopeState,
} from './mainAction.types';
import {
  clearPermissionsAction,
  clearUserInfo,
  logoutAction,
} from './mainActions';
import { LOGIN_PATH, NO_PERMISSION_PATH } from './modules/mainhome/constants';
import ForgotPassword from './modules/mainhome/LoginScreen/ForgotPassword';
import LoginMain from './modules/mainhome/LoginScreen/Loginmain';
import NoPermissionScreen from './modules/NoPermissionScreen/NoPermissionScreen';
import NavBar from './navbar/Navbar';
import styles from './navigation.module.css';
import { navigateOveride } from './override';
import env from './variables';

const cx = classNames.bind(styles);
const { getModuleNameFromRoutes, initialiseExternalLibs } = helpers;
const { isEmpty } = validators;

type Routes = {
  path: string;
  name: string;
  component: any;
  routes?: Routes[];
};

type Props = {
  initialRoute: string;
  combinedRoutes: Routes[];
};

type MainRootState = {
  main: {
    checkLogin: CheckLoginState;
    scopes: PermissionScopeState;
  };
};

const RouteWithSubRoutes = (route: Routes) => {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};

const getAllowedRoutes = (routes: Routes[], scopes: ItemsEntity[]) => {
  const panels = scopes.map((scope) => {
    const panel = scope.id.split(':');
    return panel[0];
  });

  const filteredRoutes = routes.filter((route) => {
    if (typeof route.path !== 'string') {
      return false;
    }

    const panelNameFinal = route.path
      .split('/')[1]
      .split('?')[0]
      .replace('-', '');
    console.log('panelName: ', panelNameFinal);
    return panels.includes(panelNameFinal);
  });
  console.log('panels: ', panels);
  console.log('filteredRoutes: ', filteredRoutes);
  return filteredRoutes;
};

const handleDashboradClick = () =>
  (window.location.href = window.location.origin);

const Navigation = ({ combinedRoutes }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch: types.Dispatch<any> = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  useRequireLogin(false);
  const { permissionList, user, isLoggedin, id, email, phone, username } =
    useSelector((rootState: MainRootState) => {
      const { scopes, checkLogin } = rootState['main'];

      return {
        permissionList:
          scopes && scopes.permissionList ? scopes.permissionList : [],
        user: checkLogin,
        id: checkLogin?.id ?? '',
        email: checkLogin?.email ?? '',
        phone: checkLogin?.phone ?? '',
        username: checkLogin?.name ?? '',
        isLoggedin: checkLogin ? checkLogin.isLoggedin : false,
      };
    });

  useEffect(() => {
    initialiseExternalLibs({
      environment: panelName + ':' + env.appEnv,
      sentryID: env.sentryID,
      enableErrorTracing: env.enableSentry,
      licenseKey: env.licenseKey,
      applicationID: env.applicationID,
      panelName: panelName,
      configureScope: {
        id: `${id}`,
        username,
        email,
        phone,
      },
    });
  }, [id]);

  const [init, setInit] = useState(false);
  const [mainRoutes, setMainRoutes] = useState<Routes[]>([]);
  const moduleName = getModuleNameFromRoutes(location);
  useEffect(() => {
    dispatch({ type: '@@INIT_APP', meta: { moduleName } });
    setInit(true);
  }, [moduleName, dispatch]);

  useEffect(() => {
    if (location.pathname === LOGIN_PATH) {
      setMainRoutes([]);
    } else {
      const filteredRoutes = getAllowedRoutes(combinedRoutes, permissionList);
      setMainRoutes(filteredRoutes);
      console.log({ combinedRoutes, filteredRoutes, permissionList });
      if (filteredRoutes.length === 1 && env.appEnv !== 'development') {
        const { path } = filteredRoutes[0];
        const formattedPath: string = `${path}/`;
        const isOverride = navigateOveride(formattedPath, true);
        if (!isOverride) {
          window.location.href = formattedPath;
        }
      }
    }
  }, [location.pathname, combinedRoutes, permissionList]);

  useEffect(() => {
    console.log('useEffect:adminPanel', { location, mainRoutes, isLoggedin });
    if (
      location.pathname !== NO_PERMISSION_PATH &&
      mainRoutes.length === 0 &&
      isLoggedin
    ) {
      history.push(NO_PERMISSION_PATH);
    }
  }, [location.pathname, mainRoutes, isLoggedin]);

  const handleNavigate = (path: string) => {
    setInit(false);
    const isOverride = navigateOveride(path, true);
    if (!isOverride) {
      window.location.href = path;
    }
  };

  const handleLogout = () => {
    dispatch(logoutAction())
      .then(() => {
        dispatch(clearPermissionsAction());
        dispatch(clearUserInfo());
        if (
          location.pathname === '/dashboard' ||
          location.pathname === NO_PERMISSION_PATH
        ) {
          history.push(LOGIN_PATH);
        } else if (env.appEnv !== 'development') {
          window.location.href = `${LOGIN_PATH}?ref=` + location.pathname;
        }
      })
      .catch((error) => {
        const { error: { message = '' } = {} } = error;
        setErrorMessage(message);
      });
  };

  const handleClearErrorMessage = () => setErrorMessage('');
  console.log('test', mainRoutes);
  return (
    <NavBar
      logoClick={handleDashboradClick}
      user={user}
      routes={mainRoutes}
      navigate={handleNavigate}
      handleLogout={handleLogout}
      logo={logo}
      collapsable={false}
      showTopBar={showTopbar}>
      <div
        className={cx('navChildren')}
        style={{ maxHeight: window.innerHeight }}>
        {!isEmpty(errorMessage) && (
          <ToastBar type="error" callback={handleClearErrorMessage}>
            <Text testID="error-message-text" color="error">
              {errorMessage}
            </Text>
          </ToastBar>
        )}
        {init && (
          <Switch>
            <Route path="/" exact component={LoginMain} />
            <Route path={LOGIN_PATH} exact component={LoginMain} />
            <Route path="/forgot-password/:token" component={ForgotPassword} />
            <Route path={NO_PERMISSION_PATH} component={NoPermissionScreen} />
            {mainRoutes.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Switch>
        )}
      </div>
    </NavBar>
  );
};

export default Navigation;
